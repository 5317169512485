<template>
	<div>
		<!-- Intro to understand the stage -->
		<StageIntroDialog />

		<!-- Research Question -->
		<ResearchQuestion></ResearchQuestion>

		<div class="mt-3">
			<!--  Stages of Plan Search Stratergy -->
			<component
				v-if="currentStepComponent"
				:is="currentStepComponent"
			></component>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { planSearchStrategySteps } from "@/utils/steps";
import { stepsManageMixin } from "@/mixins/stepsManageMixin";
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";
import ResearchQuestion from "@/components/research/modules/_common/ResearchQuestion.vue";
import * as stepsComponents from "@/components/research/modules/gatherSources/stages/planSearchStrategy/steps/index.js";

export default {
	name: "PlanSearchStrategyPage",

	mixins: [stepsManageMixin],

	components: {
		StageIntroDialog,
		ResearchQuestion,
		...stepsComponents,
	},

	computed: {
		...mapState({
			current_step: (state) => state.process.current_step,
			completed_step: (state) => state.process.completed_step,
			process_status_id: (state) => state.process.process_status.id,
		}),

		currentStepComponent() {
			return this.current_step ? this.current_step.component_name : null;
		},

		featureSteps() {
			return planSearchStrategySteps;
		},
	},

	mounted() {
		// This function is in mixin
		this.getAndSetSteps();
	},

	created() {
		this.$eventBus.$on("pss-redirect-back", async (payload) => {
			this.redirectBack();
		});
		this.$eventBus.$on("pss-redirect-next", async (payload) => {
			this.redirectNext();
		});
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("pss-redirect-back");
		this.$eventBus.$off("pss-redirect-next");
		this.$eventBus.$off("pss-validate-min-keywords");
	},
};
</script>
