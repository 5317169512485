<template>
	<!-- Import from Mindmap Dialog -->
	<v-dialog persistent v-model="dialog" width="680">
		<!-- Dialog Activator -->
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				v-bind="attrs"
				v-on="on"
				outlined
				rounded
				color="success"
				depressed
				class="font-weight-bold"
			>
				<v-icon left>$vuetify.icons.values.import</v-icon>
				{{ $t("app.import_from_mindmap") }}
			</v-btn>
		</template>

		<!-- Dialog Content -->
		<AppDialog
			:heading="{
				label: 'app.import_from_mindmap',
			}"
			:action="{
				label: 'app.import',
				event: 'save',
			}"
			@close="dialog = false"
			@save="dialog = false"
		>
			<template v-slot:dialog-content>
				<div>{{ $t("app.import_from_mindmap_instructions") }}</div>

				<!-- List of import keywords -->
				<KeywordsImport :show_heading="false" />
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import KeywordsImport from "@/components/research/modules/gatherSources/stages/planSearchStrategy/steps/KeywordsImport.vue";
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "KeywordsImportDialog",

	props: ["module"],

	data() {
		return {
			dialog: false,
			intro: null,
		};
	},

	components: {
		KeywordsImport,
		AppDialog,
	},
};
</script>
