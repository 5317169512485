import { convertToNum, findFromArray } from "@/utils/helpers";
import { store } from "@/store";
import { mapState } from "vuex";

export const stepsManageMixin = {
	computed: {
		...mapState({
			process_status: (state) => state.process.process_status,
			current_step: (state) => state.process.current_step,
			completed_step: (state) => state.process.completed_step,
			current_module: (state) => state.research.current_module,
			current_stage: (state) => state.research.current_stage,
		}),
	},

	methods: {
		findItem(key, matcher) {
			return findFromArray(this.featureSteps, key, matcher);
		},

		async getAndSetSteps() {
			try {
				this.$loader.start();

				// Get process status from backend.
				let response = await store.dispatch("process/getProcessStatus", {
					process: "M" + this.current_module,
					step: this.current_stage,
				});

				// Backend response is of type string, so convert to number before use.
				let completed_step = convertToNum(
					response.process_status.user_completed_step,
				);
				let current_step = convertToNum(
					response.process_status.user_current_step,
				);

				// Mutate steps in state, and pass mount param as true to identify request on mount.
				this.mutateSteps(
					{
						completed_step,
						current_step,
					},
					true,
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		mutateSteps(payload, on_mounted = false) {
			try {
				this.$loader.start();

				// Find current step obj from value
				let current_step = this.findItem("value", payload.current_step);

				/** if completed step at server is 0 that means user
				 * hasn't completed any step yet, so leave completed step
				 * as it is by making an dummy obj.
				 */
				let completed_step =
					payload.completed_step === 0
						? { value: 0 }
						: this.findItem("value", payload.completed_step);

				// If completed step is not found then consider 0th step as completed.
				if (completed_step === undefined) {
					completed_step = this.featureSteps[0];
				}

				// If current step is not found then consider 0th step as current.
				if (current_step === undefined) {
					current_step = this.featureSteps[0];
				}

				/**
				 * Condition should be ---> current_step >= completed_step
				 * So, if current step is less or equal to completed step then set current step
				 * as follows-
				 * 1. current step should be completed step's next step.
				 * 2. current step should be equal to completed step if completed step's next
				 * step is null.
				 * "--------------NOTE----------------------"
				 * This condition is only to check on mount because in case of back
				 * completed would always be greater than current and in case of next this condition
				 * would not happen anyhow.
				 */
				if (on_mounted && current_step.value <= completed_step.value) {
					current_step = this.findItem(
						"name",
						completed_step.next_step
							? completed_step.next_step
							: completed_step.name,
					);
				}
				store.commit("process/SET_CURRENT_AND_COMPLETED_STEP", {
					current_step,
					completed_step,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async redirectBack() {
			try {
				this.$loader.start();

				/**
				 * When going to back-
				 * current_step = Recent step's previous step.
				 * completed_step = no change
				 */
				if (!this.current_step.previous_step) return;

				let result = this.findItem("name", this.current_step.previous_step);
				if (!result) return;

				let current_step = result.value;
				let completed_step = this.completed_step.value;

				// On back, don't save to database only mutate in state.
				this.mutateSteps({
					current_step,
					completed_step,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async redirectNext() {
			try {
				this.$loader.start();

				/**
				 * When going to next-
				 * current_step = Recent step's next step.
				 * completed_step = if greater than current then no change otherwise Recent step.
				 */
				if (!this.current_step.next_step) return;

				let result = this.findItem("name", this.current_step.next_step);
				if (!result) return;

				let current_step = result.value;
				let completed_step =
					this.completed_step.value >= this.current_step.value
						? this.completed_step.value
						: this.current_step.value;

				await this.saveSteps({
					current_step,
					completed_step,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async saveSteps(payload) {
			// Save to database
			await store.dispatch("process/setProcessStatus", {
				process_status_id: this.process_status.id,
				user_completed_step: payload.completed_step,
				user_current_step: payload.current_step,
			});

			// Save to state
			this.mutateSteps({
				current_step: payload.current_step,
				completed_step: payload.completed_step,
			});
		},
	},
};
