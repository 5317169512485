var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"680"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-bold",attrs:{"outlined":"","rounded":"","color":"success","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("$vuetify.icons.values.import")]),_vm._v(" "+_vm._s(_vm.$t("app.import_from_mindmap"))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('AppDialog',{attrs:{"heading":{
			label: 'app.import_from_mindmap',
		},"action":{
			label: 'app.import',
			event: 'save',
		}},on:{"close":function($event){_vm.dialog = false},"save":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.$t("app.import_from_mindmap_instructions")))]),_c('KeywordsImport',{attrs:{"show_heading":false}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }