<template>
	<v-card flat class="wr_grey_1">
		<v-card-title v-if="show_heading" class="wr_blue_1--text font-weight-black">
			{{ $t("app.keywords") }}
		</v-card-title>

		<v-card-text>
			<v-chip-group column>
				<v-chip
					v-for="keyword in mindMapKeywords"
					:key="keyword.id"
					color="info"
					:text-color="isExistsInList(keyword.name) ? 'white' : ''"
					:outlined="!isExistsInList(keyword.name)"
					@click.stop="importKeyword(keyword.name)"
				>
					<v-icon v-if="isExistsInList(keyword.name)" left
						>$vuetify.icons.values.check</v-icon
					>
					{{ keyword.name }}
				</v-chip>
			</v-chip-group>
		</v-card-text>

		<!-- Suggested by AI -->
		<v-card-title class="wr_blue_1--text font-weight-black">
			{{ $t("app.suggested_keywords") }}
		</v-card-title>

		<v-card-text>
			<v-chip-group column>
				<v-chip
					v-for="keyword in ai_suggested_keywords"
					:key="keyword.id"
					color="info"
					:text-color="isExistsInList(keyword.name) ? 'white' : ''"
					:outlined="!isExistsInList(keyword.name)"
					@click.stop="importKeyword(keyword.name)"
				>
					<v-icon v-if="isExistsInList(keyword.name)" left
						>$vuetify.icons.values.check</v-icon
					>
					{{ keyword.name }}
				</v-chip>
			</v-chip-group>
		</v-card-text>
	</v-card>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { findFromArray } from "@/utils/helpers";
import { questionnaireHelper } from "@/utils/helpers/questionnaire.js";
import { toolsQueryMixin } from "@/mixins/tools/queryMixin.js";
import { mapFields } from "vuex-map-fields";

export default {
	name: "PlanSearchStrategyKeywordsImport",

	mixins: [toolsQueryMixin],

	props: {
		show_heading: {
			default: true,
		},
	},

	computed: {
		...mapState({
			mindMapKeywords: (state) => state.keyword.mindmap_keywords,
			list: (state) => state.keyword.list,
		}),
		...mapGetters({
			isExistsInList: "keyword/isExistsInList",
		}),
		...mapFields("keyword", {
			ai_suggested_keywords: "ai_suggested_keywords",
		}),
	},

	mounted() {
		this.setUpKeywords();
	},

	methods: {
		// This function jas no real use, only written because using toolsQueryMixin
		readyToListenEvents() {},

		async setUpKeywords() {
			try {
				this.$loader.start();
				// Fetch mind map keywords
				await this.$store.dispatch("keyword/mindMapKeywords");
				// Fetch AI suggested keywords
				if (!this.ai_suggested_keywords || !this.ai_suggested_keywords.length) {
					await this.fetchKeywordsUsingGPT();
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async fetchKeywordsUsingGPT() {
			try {
				this.$loader.start();
				// Fetch the research topic
				let topic = await questionnaireHelper.getResearchTopic();
				// Send query
				let query = `Generate a JSON of 8 keywords related to "${topic.answer} in a style of a researcher. The JSON should be in this format - [{"id": 1, "name": "keyword_name", "selected": false}]."`;
				// Prepare query
				let response = await this.prepareQuery(query);
				// Parse the GPT response and assign to some variable
				this.ai_suggested_keywords = JSON.parse(response.result);
			} catch (error) {
				throw error;
			} finally {
				this.$loader.stop();
			}
		},

		async importKeyword(keyword) {
			try {
				this.$loader.start();

				// Create this as a new keyword only if not created already
				if (!this.isExistsInList(keyword)) {
					await this.$store.dispatch("keyword/create", {
						name: keyword,
						selected: false,
					});
				}
				// Else, find keyword's id by name because mind map keyword has uuid, so get id from list
				else {
					let result = findFromArray(this.list, "name", keyword);
					if (result) {
						await this.$store.dispatch("keyword/del", {
							id: result.id,
						});
					}
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
