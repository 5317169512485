<template>
	<div>
		<v-card flat class="wr_grey_1">
			<!-- Import Keywords -->
			<v-card-title class="wr_blue_1--text font-weight-black">
				<v-row>
					<v-col>{{ $t("app.keyword_list") }} </v-col>
					<v-col align="end">
						<div v-if="can_import_keywords" class="d-inline-block">
							<KeywordsImportDialog />
						</div>
						<div class="d-inline-block ms-3">
							<KeywordAddDialog />
						</div>
					</v-col>
				</v-row>
			</v-card-title>

			<!-- KEYWORDS LIST -->
			<v-card-text class="mt-3">
				<v-row>
					<v-col sm="6" v-for="(keyword, index) in list" :key="index">
						<!-- Display added keyword -->
						<v-text-field
							readonly
							color="greenAccent2"
							background-color="white"
							hide-details
							:value="keyword.name"
							dense
							outlined
							:append-icon="
								can_delete_keywords ? $vuetify.icons.values.cancel : null
							"
							@click:append="confirmDeletion(keyword)"
						>
							<template slot="prepend">
								<v-checkbox
									v-if="can_select_keywords"
									v-model="keyword.selected"
									color="wr_blue_1"
									:true-value="true"
									:false-value="false"
									:on-icon="$vuetify.icons.values.check_outline"
									@click="toggleFav(keyword)"
								></v-checkbox>
							</template>
						</v-text-field>
					</v-col>
				</v-row>

				<!-- Confirmation box for delete operation -->
				<TheConfirmation
					v-model="dialog"
					:dialog="dialog"
					@cancel="dialog = false"
					@confirm="keywordDel()"
				></TheConfirmation>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import KeywordsImportDialog from "@/components/research/modules/gatherSources/KeywordsImportDialog.vue";
import KeywordAddDialog from "@/components/research/modules/gatherSources/KeywordAddDialog.vue";
import TheConfirmation from "@/components/layout/TheConfirmation";

export default {
	name: "PlanSearchStrategyKeywordsList",

	props: {
		can_import_keywords: {
			default: true,
		},
		can_select_keywords: {
			default: false,
		},
		can_delete_keywords: {
			default: true,
		},
	},

	data() {
		return {
			dialog: false,
			del_keyword: null,
		};
	},

	components: {
		KeywordsImportDialog,
		KeywordAddDialog,
		TheConfirmation,
	},

	computed: {
		...mapState({
			list: (state) => state.keyword.list,
			min_keywords: (state) =>
				state.process.rsc_general.min_candidates_keywords,
		}),

		...mapGetters({
			keywords_added: "keyword/addedListCount",
		}),
	},

	created() {
		/**
		 * We are importing this component at somewhere also, so there
		 * this event will be created too. That's why we need to check if it is
		 * not created already then only create the event.
		 */
		if (!this.$eventBus._events["pss-validate-min-keywords"]) {
			this.$eventBus.$on("pss-validate-min-keywords", async (payload) => {
				this.validateMinKeywords();
			});
		}
	},

	mounted() {
		this.getList();
	},

	methods: {
		async getList() {
			try {
				await this.$store.dispatch("keyword/list");
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async toggleFav(keyword) {
			try {
				this.$loader.start();

				await this.$store.dispatch("keyword/toggleFav", {
					keywordId: keyword.id,
					selected: keyword.selected,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		confirmDeletion(keyword) {
			this.del_keyword = keyword;
			this.dialog = true;
		},

		async keywordDel() {
			try {
				this.$loader.start();

				await this.$store.dispatch("keyword/del", {
					id: this.del_keyword.id,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
				this.dialog = false;
			}
		},

		validateMinKeywords() {
			try {
				// Check validation
				if (this.keywords_added < this.min_keywords) {
					throw new Error(
						this.$t(
							!this.keywords_added
								? "app.ntfy.err.min_keywords"
								: "app.ntfy.err.more_keywords_required",
							{
								min_keywords: this.min_keywords - this.keywords_added,
							},
						),
						{
							cause: "werCustom",
						},
					);
				}

				// Else, pass to next step
				this.$eventBus.$emit("pss-redirect-next");
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
>>> .v-input--selection-controls {
	margin: 0px;
	padding-top: 0px;
}
</style>
