<template>
	<div>
		<KeywordsList
			:can_import_keywords="false"
			:can_select_keywords="true"
			:can_delete_keywords="false"
		/>

		<!-- Show finish plan search strategy  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.plan_search_strategy.title"
			@close="dialog = false"
		></StageFinishDialog>
	</div>
</template>

<script>
import KeywordsList from "@/components/research/modules/gatherSources/stages/planSearchStrategy/steps/KeywordsList.vue";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import { mapState, mapGetters } from "vuex";

export default {
	name: "PlanSearchStrategyKeywordsSelect",

	data() {
		return {
			dialog: false,
			min_limit: 3,
		};
	},

	components: {
		KeywordsList,
		StageFinishDialog,
	},

	computed: {
		...mapGetters({
			favCount: "keyword/favCountOfAddedList",
		}),
		...mapState({
			max_fav_keywords: (state) =>
				state.process.rsc_general.max_favorites_keywords,
		}),
	},

	created() {
		this.$eventBus.$on("pss-validate-max-keywords", (payload) => {
			this.validateMaxKeywords();
		});
	},

	mounted() {
		this.getFavList();
	},

	methods: {
		validateMaxKeywords() {
			try {
				this.$loader.start();

				// Check validation
				if (
					this.favCount < this.min_limit ||
					this.favCount > this.max_fav_keywords
				) {
					throw new Error(
						this.$t("app.ntfy.err.fav_keywords", {
							max_fav_keywords: this.max_fav_keywords,
						}),
						{
							cause: "werCustom",
						},
					);
				}

				// Else, pass to next step
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getFavList() {
			try {
				this.$loader.start();

				await this.$store.dispatch("keyword/favlist");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("pss-validate-max-keywords");
	},
};
</script>
